import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import moment from 'moment'
import { Line } from '@ant-design/plots'
import {
  Layout,
  theme,
  Breadcrumb,
  Dropdown,
  message,
  Row,
  Col,
  Statistic,
  Table
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import axios from '../../utils/api'
import to from 'await-to-js'

const { Content } = Layout
const formatter = value => <CountUp end={value} separator="," />

function mergeAndCalculate (data) {
  // 用于存储结果的数组
  const mergedData = []

  // 循环遍历数据
  data.forEach(entry => {
    // 遍历每项数据中的每个字段（除了_id）
    Object.keys(entry).forEach(key => {
      if (key !== '_id') {
        // 查找或创建在mergedData中与当前日期和字段相匹配的对象
        const existingEntry = mergedData.find(e => e.date === entry._id && e.category === key)
        if (existingEntry) {
          existingEntry.value += entry[key] // 如果已存在相同日期和字段的对象，我们累加值
        } else {
          // 否则，创建一个新对象并添加到mergedData数组中
          if (key !== 'payment_amount') {
            mergedData.push({
              date: entry._id,
              value: entry[key],
              category: key
            })
          }
        }
      }
    })
  })

  const result = Array.from(new Set(mergedData.map(item => JSON.stringify(item)))).map(item => JSON.parse(item))
  console.log(result)
  return result
}

const Component = () => {
  const {
    token: { colorBgContainer, borderRadiusLG }
  } = theme.useToken()

  const [shows, setShows] = useState([])
  const [currentShow, setCurrentShow] = useState(null)
  const [verifyCount, setVerifyCount] = useState(0)
  const [saleCount, setSaleCount] = useState(0)
  const [saleTotal, setSaleTotal] = useState('0.0')
  const [verifyTotal, setVerifyTotal] = useState('0.0')
  const [tickets, setTickets] = useState([])
  const [tableLoaddingStatus, setTableLoaddingStatus] = useState(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [lineData, setLineData] = useState([])

  const getShows = async () => {
    const [err, response] = await to(
      axios.get('/jwt/shows?include=none', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
    )
    if (err) {
      message.error(`加载失败:${err.message}`)
    }

    const { results } = response.data
    setShows(results)
  }

  // const getReport = async (activityId) => {
  //   const [err, response] = await to(
  //     axios.get('/jwt/ticket-confirm-report', {
  //       method: 'GET',
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('token')}`
  //       },
  //       params: {
  //         activityId
  //       }
  //     })
  //   )

  //   if (err) {
  //     message.error(`加载失败:${err.message}`)
  //   }

  //   const verifyData = response.data.results.verified || []
  //   setVerifyCount(verifyData.map(v => Number(v.total)).reduce((a, b) => (a + b), 0))
  // }

  const getSaleData = async (showId) => {
    setTableLoaddingStatus(true)
    const [err, response] = await to(
      axios.get('/jwt/ticket-report', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          showId
        }
      })
    )

    if (err) {
      message.error(`加载失败:${err.message}`)
      return
    }
    const saleData = []
    const sellTotal = []
    let totalSales = 0.0
    let verifySales = 0.0
    let showVerifyCount = 0
    const data = response.data.results
    const { activityEvents = [] } = data

    activityEvents.forEach(event => {
      const { ticketCategories = [] } = event
      ticketCategories.forEach(category => {
        const countInfo = category.countInfo || [{ count: 0, verify: 0 }]

        const dayCountInfo = {}
        const verifyCountInfo = {}

        const today = moment().format('YYYY-MM-DD')
        const yeaterday = moment().subtract(1, 'days').format('YYYY-MM-DD')

        countInfo.forEach(v => {
          Object.assign(dayCountInfo, { [v._id]: v.count })
          Object.assign(verifyCountInfo, { [v._id]: v.verify })
        })

        const toadyCountInfo = countInfo.filter(v => v._id === today)

        let todayStat = {}
        if (toadyCountInfo.length) {
          todayStat = toadyCountInfo[0]
        } else {
          todayStat = { count: 0, verify: 0 }
        }

        const prevday = { count: dayCountInfo[yeaterday] || 0, verify: verifyCountInfo[yeaterday] || 0 }
        const total = countInfo.filter(v => v._id === '合计')[0]

        sellTotal.push(total)

        const totalVerify = total ? total.verify : 0
        const totaCount = total ? total.count : 0
        const salePrice = category.ticketGroup ? category.ticketGroup.salePrice : 0

        const ticketTotalSales = parseFloat(totaCount * Number(salePrice))
        totalSales += ticketTotalSales

        const ticketTotalVerifySales = parseFloat(totalVerify * Number(salePrice))
        showVerifyCount += totalVerify
        verifySales += ticketTotalVerifySales

        const writeOffCount = (countInfo.length === 1 || countInfo.length === 0) ? 0 : countInfo.reduce((pre, cur) => ((pre.writeoff_count || 0) + (cur.writeoff_count || 0)))

        const countInfoObj = countInfo[countInfo.length ? countInfo.length - 1 : 0]

        const { count = 0, verify = 0 } = countInfoObj
        const ticketVerifyTotalSales = parseFloat(verify * Number(salePrice))

        const saleItem = {
          ...countInfoObj,
          dayCountInfo,
          verifyCountInfo,
          title: category.specification,
          salePrice,
          writeOffCount,
          countTotalPrice: `¥ ${ticketTotalSales.toFixed(2)} 元 / ${count} 张`,
          verifyTotalPrice: `¥ ${ticketVerifyTotalSales.toFixed(2)} 元/ ${verify} 张`,
          totalCountTotalPrice: `¥ ${(parseFloat(todayStat.count * Number(salePrice))).toFixed(2)} 元 / ${todayStat.count} 张`,
          totalVerifyTotalPrice: `¥ ${(parseFloat(todayStat.verify * Number(salePrice))).toFixed(2)} 元 / ${todayStat.verify} 张`,
          prevCountTotalPrice: `¥ ${(parseFloat(prevday.count * Number(salePrice))).toFixed(2)} 元 / ${prevday.count} 张`,
          prevtotalVerifyTotalPrice: `¥ ${(parseFloat(prevday.verify * Number(salePrice))).toFixed(2)} 元 / ${prevday.verify} 张`
        }
        saleData.push(saleItem)
      })
    })

    const tickets = activityEvents.map(v => v.ticketCategories).flat().map(v => ({ key: v.objectId, ...v }))
    setTickets(tickets)
    const statisticData = tickets.map(v => v.countInfo).flat().filter(v => v._id !== '合计')
    const lineData = mergeAndCalculate(statisticData)

    setLineData(lineData.sort((a, b) => {
      const aUTC = moment(a.date)
      const bUTC = moment(b.date)
      return (aUTC.utc() - bUTC.utc())
    }))

    setVerifyCount(showVerifyCount)
    setSaleCount(sellTotal.map(v => v.count).reduce((pre, cur) => (Number(pre) + Number(cur)), 0))
    setSaleTotal(parseFloat(totalSales).toFixed(2))
    setVerifyTotal(parseFloat(verifySales).toFixed(2))
    setTableLoaddingStatus(false)
  }

  useEffect(() => {
    if (currentShow) {
      setVerifyCount(0)
      setSaleCount(0)
      setSaleTotal('0.00')
      setVerifyTotal('0.00')
      // getReport(currentShow.activityId)
      getSaleData(currentShow.activityId)

      localStorage.setItem('currentShowId', currentShow.objectId)
    }
  }, [currentShow])

  useEffect(() => {
    if (!currentShow) {
      const savedShowId = localStorage.getItem('currentShowId')
      if (!savedShowId && shows.length) {
        setCurrentShow(shows[0])
      } else {
        const show = shows.find(v => v.objectId === savedShowId)
        setCurrentShow(show)
      }
    }
  }, [shows])

  useEffect(() => {
    getShows()
  }, [])

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Dropdown
            overlayStyle={{ backgroundColor: '#fff', maxHeight: '10vh' }}
            trigger={['hover']}
            menu={{
              onClick: e => {
                const obj = shows.find(v => v.objectId === e.key)
                setCurrentShow(obj)
              },
              items: shows.map(v => ({
                key: v.objectId,
                label: v.name
              })),
              style: { maxHeight: '300px', overflowY: 'auto' }
            }}
          >
            <div className="cursor-pointer font-semibold text-black">
              {currentShow && currentShow.name}&nbsp;
              <DownOutlined style={{ fontSize: '10px', fontWeight: 400 }} />
            </div>
          </Dropdown>
        </Breadcrumb.Item>
        <Breadcrumb.Item>数据统计</Breadcrumb.Item>
      </Breadcrumb>
      <Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
          background: colorBgContainer,
          borderRadius: borderRadiusLG
        }}
      >
        <Row justify="center" style={{ margin: 20 }}>
          <Col span={6}>
            <Statistic
              title="验票总数"
              value={verifyCount}
              formatter={formatter}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="销售总数"
              value={saleCount}
              precision={2}
              formatter={formatter}
              />
          </Col>
          <Col span={6}>
            <Statistic
              title="销售总额(元）"
              value={saleTotal}
              precision={2}
              formatter={<CountUp start={0.00} end={saleTotal} separator="," decimal='2' />}
              />
          </Col>
          <Col span={6}>
            <Statistic
              title="验票总额(元）"
              value={Number(verifyTotal)}
              precision={2}
              formatter={<CountUp start={0.00} end={Number(verifyTotal)} separator="," decimal='2' />}
              decimalSeparator=","
              />
          </Col>
        </Row>

        <Line data= {lineData}
          xField='date'
          yField= 'value'
          seriesField='category'
          colorField= 'category'
          interaction={{
            tooltip: {
              render: (event, { title, items }) => {
                return (
                  <div>
                    <div>{title}</div>
                    {items.map(v => {
                      return <div key={`${title}-${v.name}`}>{v.name === 'count' ? '销售' : '核销'} : {v.value}</div>
                    })}
                  </div>
                )
              }
            }
          }}
          legend= {false}
        />

        <Table loading={tableLoaddingStatus} dataSource={tickets} columns={[
          {
            title: '票档名称',
            key: 'title',
            align: 'left',
            render: (_, record) => {
              return <div>{record.specification}</div>
            }
          },
          {
            title: '总销售',
            key: 'saleTotal',
            align: 'right',
            width: '250px',
            render: (_, record) => {
              const { countInfo = [], originPrice } = record
              const count = countInfo.filter(({ _id }) => _id !== '合计').map(({ count }) => count).reduce((a, b) => (a + b), 0)
              return <div>¥ {parseFloat(Number(count) * originPrice).toFixed(2)} 元 / {count} 票</div>
            }
          },
          {
            title: '总核销（票）',
            key: 'verifyTotal',
            align: 'right',
            width: '250px',
            render: (_, record) => {
              const { countInfo = [], originPrice } = record
              const count = countInfo.filter(({ _id }) => _id !== '合计').map(({ verify }) => verify).reduce((a, b) => (a + b), 0)
              return <div>¥ {parseFloat(Number(count) * originPrice).toFixed(2)} 元 / {count} 票</div>
            }
          }
        ]}
          expandable={{
            expandedRowKeys,
            onExpand: (expanded, record) => {
              const key = record.objectId
              let newExpandedRowKeys
              if (expanded) {
                newExpandedRowKeys = [...expandedRowKeys, key]
              } else {
                newExpandedRowKeys = expandedRowKeys.filter(rowKey => rowKey !== key)
              }
              newExpandedRowKeys = Array.from(new Set(newExpandedRowKeys))
              setExpandedRowKeys(newExpandedRowKeys)
            },
            expandedRowRender: (record) => {
              return <Table
                columns={ [
                  {
                    title: '日期',
                    key: 'saleDate',
                    align: 'left',
                    dataIndex: '_id'
                  },
                  {
                    title: '销售数',
                    key: 'saleTotalByDate',
                    align: 'right',
                    width: '250px',
                    render: (_, countInfoItem) => {
                      const { count } = countInfoItem
                      return <div>¥ {parseFloat(Number(count) * record.originPrice).toFixed(2)} 元 / {count} 票</div>
                    }
                  },
                  {
                    title: '核销数',
                    key: 'verifyTotalByDate',
                    align: 'right',
                    width: '250px',
                    render: (_, countInfoItem) => {
                      const { verify: count } = countInfoItem
                      return <div>¥ {parseFloat(Number(count) * record.originPrice).toFixed(2)} 元 / {count} 票</div>
                    }
                  }
                ]}
                dataSource={
                  record.countInfo.filter(({ _id }) => _id !== '合计').map(v => ({
                    key: `${record.objectId}-${v._id}`,
                    ...v
                  })).sort((a, b) => ((new Date(b._id)) - (new Date(a._id))))
                }
                pagination={true} />
            }
          }}
        />
      </Content>
    </div>
  )
}

export default Component
