import React, { useEffect, useState } from 'react'
import {
  Layout,
  theme,
  Table,
  message,
  Input,
  Tag,
  Image,
  Button,
  Popconfirm,
  Breadcrumb
} from 'antd'
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import to from 'await-to-js'
import axios from '../../utils/api'

const { Content } = Layout

const Component = () => {
  const {
    token: { colorBgContainer, borderRadiusLG }
  } = theme.useToken()

  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [dataLoading, setDataLoading] = useState(false)

  const getOrders = async (page = 1, pageSize = 10, opts) => {
    setDataLoading(true)
    const [err, response] = await to(
      axios.get('/jwt/orders', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          page,
          limit: pageSize,
          ...opts
        }
      })
    )
    if (err) {
      message.error(`加载失败:${err.message}`)
    }

    const { results } = response.data
    setData(results.data)
    setTotal(results.count)
    setDataLoading(false)
  }

  const verifyAction = async (item, receiptIndex) => {
    const [err, response] = await to(
      axios.post(
        '/jwt/receipt-verify',
        {
          serialNumber: item.ticketReceipts[receiptIndex].serialNumber
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
    )
    if (err) {
      message.error(`加载失败:${err.message}`)
    }

    if (response && response.status === 200) {
      const itemIndex = data.findIndex(data => data.orderId === item.orderId)
      const newDataSource = [...data]
      const newItem = { ...newDataSource[itemIndex] }
      newItem.ticketReceipts[receiptIndex] = {
        ...newItem.ticketReceipts[receiptIndex],
        verifyTime: Date.now(),
        status: 2
      }
      newDataSource[itemIndex] = newItem
      setData(newDataSource)
      message.success('核销成功')
    }
  }

  const onSearch = str => {
    const phoneRegex = /^1\d{10}$/
    const orderIdRegex = /^\d+$/
    const serialNumberRegex = /^[0-9a-fA-F]{8}$/
    const opts = {}

    if (phoneRegex.test(str)) {
      Object.assign(opts, { phone: str })
    } else if (orderIdRegex.test(str)) {
      Object.assign(opts, { orderId: str })
    } else if (serialNumberRegex.test(str)) {
      Object.assign(opts, { serialNumber: str })
    } else {
      if (str !== '') {
        message.error('请输入正确格式的内容')
        return
      }
    }

    getOrders(1, 10, opts)
  }

  useEffect(() => {
    getOrders()
  }, [])

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>所有展览</Breadcrumb.Item>
        <Breadcrumb.Item>票务查询</Breadcrumb.Item>
      </Breadcrumb>
      <Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
          background: colorBgContainer,
          borderRadius: borderRadiusLG
        }}
      >
        <div className="flex justify-end mb-5">
          <Input.Search
            style={{ width: '30%' }}
            placeholder="请输入手机号/订单号/票号查询"
            onSearch={onSearch}
            enterButton
            allowClear
          />
        </div>

        <Table
          columns={[
            {
              title: '展览信息',
              key: 'name',
              render: (_, item) => (
                <div className="flex flex-row">
                  <Image
                    width={100}
                    src={`${item.content.activity.poster}?imageView2/2/w/100|imageMogr2/gravity/Center/crop/100x100`}
                    preview={{
                      src: `${item.content.activity.poster}`
                    }}
                  />
                  <div className="mx-2">
                    <div className="mt-2">
                      {item.content.ticketCategory.specification}
                    </div>
                    <div className="mb-2 text-sm">
                      {item.content.activity.name}
                    </div>
                    <div className="mt-2 block text-xs">
                      订单号:
                      <span className="text-slate-500 mx-2">
                        {item.orderId}
                      </span>
                    </div>
                  </div>
                </div>
              )
            },
            {
              title: '姓名',
              key: 'receiverName',
              dataIndex: 'receiverName',
              width: '120px',
              align: 'center',
              render: (_, item) => {
                return (
                  <div>
                    <div>{item.receiverName}</div>
                    <div className="text-xs text-slate-400">
                      {item.phone.slice(0, 3)}****{item.phone.slice(7)}
                    </div>
                  </div>
                )
              }
            },
            {
              title: '数量',
              key: 'count',
              width: '100px',
              align: 'center',
              dataIndex: 'count'
            },
            {
              title: '下单时间',
              key: 'createdAt',
              align: 'center',
              width: '150px',
              render: (_, item) => item.createdAt
            },
            {
              title: '票号',
              key: 'serialNumber',
              align: 'center',
              width: '300px',
              fixed: 'right',
              render: (_, item) => (
                <>
                  {item.ticketReceipts.map((receipt, receiptIndex) => {
                    const tagProps = {
                      icon: <ExclamationCircleOutlined />,
                      title: '未核销',
                      color: 'success'
                    }
                    if (receipt.verifyTime !== 0) {
                      Object.assign(tagProps, {
                        icon: <CheckCircleOutlined />,
                        title: '已核销',
                        color: 'default'
                      })
                    }
                    return (
                      <div
                        key={receipt.objectId}
                        className="my-2 cursor-pointer flex flex-row justify-center items-center"
                      >
                        <div className="mr-2">{receipt.serialNumber}</div>
                        <Tag {...tagProps}>{tagProps.title}</Tag>
                        <Popconfirm
                          title="是否核销?"
                          description={`是否把 票号 ：${receipt.serialNumber} 进行核销？`}
                          okText="确认"
                          cancelText="取消"
                          onConfirm={() => {
                            verifyAction(item, receiptIndex)
                          }}
                        >
                          <Button
                            type="primary"
                            size="small"
                            disabled={receipt.verifyTime !== 0}
                          >
                            点击核销
                          </Button>
                        </Popconfirm>
                      </div>
                    )
                  })}
                </>
              )
            }
          ]}
          dataSource={data}
          loading={dataLoading}
          size="small"
          pagination={{
            position: ['bottomRight'],
            onChange: (page, pageSize) => getOrders(page, pageSize),
            total
          }}
          scroll={{
            x: 1300
          }}
        />
      </Content>
    </div>
  )
}
export default Component
