import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import Login from './components/login'
import Layout from './components/layout'
import OrderQuery from './components/orderQuery'
import StaffQuery from './components/staffQuery'
import DataAnalysis from './components/dataAnalysis'

function App () {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<Layout />}>
          <Route index path="/" element={<DataAnalysis />} />
          <Route path="/data-analysis" element={<DataAnalysis />} />
          <Route path="/checker-query" element={<StaffQuery />} />
          <Route path="/order-query" element={<OrderQuery />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default App
