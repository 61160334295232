import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import {
  BookOutlined,
  SearchOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Layout, Menu, theme, Dropdown, Avatar } from 'antd'
const { Header, Sider } = Layout

const items2 = [
  {
    key: 'data-analysis',
    icon: React.createElement(BookOutlined),
    label: '数据统计',
    path: '/data-analysis'
  },
  {
    key: 'checker-query',
    icon: React.createElement(UserOutlined),
    label: '验票员',
    path: '/checker-query'
  },
  {
    key: 'order-query',
    icon: React.createElement(SearchOutlined),
    label: '票务查询',
    path: '/order-query'
  }
]
const App = () => {
  const navigate = useNavigate()
  const [isLogin, setIsLogin] = useState(false)
  const [username, setUsername] = useState('')

  const {
    token: { colorBgContainer }
  } = theme.useToken()

  useEffect(() => {
    const loginFlag = !!localStorage.getItem('token')
    setUsername(localStorage.getItem('name'))
    setIsLogin(loginFlag)
    if (!loginFlag) {
      navigate('/login', { replace: true })
    }
  }, [])

  return (
    <Layout
      style={{
        height: '100vh'
      }}
    >
      <Header
        style={{
          padding: '0px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div className="text-white pl-10">知亦行验票系统</div>
        <div className="flex flex-row text-white">
          当前用户：{username}
          <div className="bg-white ml-10">
            <Dropdown
              overlayStyle={{ backgroundColor: '#fff' }}
              trigger={['hover']}
              menu={{
                onClick: e => {
                  switch (e.key) {
                    case 'logout':
                      localStorage.clear()
                      navigate('/login')
                      break
                    default:
                  }
                },
                items: [
                  {
                    key: 'reset',
                    icon: React.createElement(SettingOutlined),
                    label: '修改密码'
                  },
                  {
                    key: 'logout',
                    icon: React.createElement(LogoutOutlined),
                    label: '退出'
                  }
                ]
              }}
            >
              <Avatar
                shape="square"
                style={{ backgroundColor: '#fff', color: '#000' }}
                size={64}
                icon={<UserOutlined />}
              />
            </Dropdown>
          </div>
        </div>
      </Header>
      <Layout>
        <Sider
          width={200}
          style={{
            background: colorBgContainer
          }}
        >
          <Menu
            mode="inline"
            onClick={e => navigate(`/${e.key}`)}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{
              height: '100%',
              borderRight: 0
            }}
            items={items2}
          />
        </Sider>
        <Layout
          style={{
            padding: '0 24px 24px'
          }}
        >
          {isLogin && <Outlet />}
        </Layout>
      </Layout>
    </Layout>
  )
}
export default App
