import React from 'react'
import { useNavigate } from 'react-router-dom'
import to from 'await-to-js'
import axios from '../../utils/api'
import { Button, Form, Input, message } from 'antd'
import { LockOutlined, PhoneOutlined } from '@ant-design/icons'

import './index.less'

const Page = () => {
  const navigate = useNavigate()

  const onFinish = async values => {
    const [err, response] = await to(axios.post('/admin/login', values))

    if (err) {
      console.error('Login failed:', err)
      message.error(`登录失败：${err.message}`)
      return
    }

    const auth = response.data.results

    // 保存登录信息，比如 token
    localStorage.setItem('objectId', auth.objectId)
    localStorage.setItem('name', auth.name)
    localStorage.setItem('email', auth.email)
    localStorage.setItem('token', auth.token)
    localStorage.setItem('createdAt', auth.createdAt)

    message.success('登录成功!')
    // 登录成功后重定向到主页

    setTimeout(() => {
      navigate('/')
    }, 3000)
  }
  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 sm:w-full">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          知亦行验票系统
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <Form
          name="normal_login"
          className="space-y-6"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              手机号码
            </label>
            <div className="mt-2">
              <Form.Item
                name="phone"
                rules={[{ required: true, message: '请输入你的手机号码!' }]}
              >
                <Input
                  prefix={<PhoneOutlined className="site-form-item-icon" />}
                  placeholder="手机号码"
                />
              </Form.Item>
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                密码
              </label>
              {/* <div class="text-sm">
                <a href="#" class="font-semibold text-indigo-600 hover:text-indigo-500">忘记密码?</a>
              </div> */}
            </div>
            <div className="mt-2">
              <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入登录密码' }]}
              >
                <Input
                  type="password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="登录密码"
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="flex w-full justify-center align-middle rounded-md bg-indigo-600 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
export default Page
