import axios from 'axios'
const axiosInstance = axios.create({
  baseURL: 'https://www.zhanapp.com.cn/openapi'
  // 其他配置...
})

// 添加请求拦截器
// axiosInstance.interceptors.request.use(config => {
//   // 在发送请求之前做些什么，比如添加token
//   config.headers.Authorization = `Bearer ${yourAuthToken}`
//   return config
// }, error => {
//   // 对请求错误做些什么
//   return Promise.reject(error)
// })

// 添加响应拦截器
axiosInstance.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    console.log('axios')
    return response
  },
  error => {
    // 对响应错误做点什么
    if (error.response) {
      // 检查状态码是否为401
      if (error.response.status === 401) {
        // 这里可以执行您需要的操作，比如重定向到登录页面或显示错误消息
        console.log('未授权访问，需要用户重新登录')
        // 可以进行重定向或其他处理
        window.location = '/login'

        // 还可以使用其他方法来处理验证问题，例如刷新令牌
      }
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
