import React, { useEffect, useState } from 'react'
import {
  Layout,
  theme,
  Breadcrumb,
  Dropdown,
  message,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Popconfirm
} from 'antd'

import {
  DownOutlined,
  UserAddOutlined,
  UserDeleteOutlined
} from '@ant-design/icons'
import axios from '../../utils/api'
import to from 'await-to-js'

const { Content } = Layout

const Component = () => {
  const {
    token: { colorBgContainer, borderRadiusLG }
  } = theme.useToken()

  const [shows, setShows] = useState([])
  const [data, setData] = useState([])
  const [currentShow, setCurrentShow] = useState(null)
  const [dataLoading, setDataLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [visible, setVisile] = useState(false)
  const [form] = Form.useForm()

  const getShows = async () => {
    const [err, response] = await to(
      axios.get('/jwt/shows?include=none', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
    )
    if (err) {
      message.error(`加载失败:${err.message}`)
    }

    const { results } = response.data
    setShows(results)
  }

  const getData = async (page = 1, pageSize = 10, opts) => {
    setDataLoading(true)
    const [err, response] = await to(
      axios.get('/jwt/ticket-oper', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          page,
          limit: pageSize,
          ...opts
        }
      })
    )

    if (err && response !== null) {
      message.error(`加载失败:${err.message}`)
      return
    }

    const { results } = response.data
    setData(results.data)
    setTotal(results.count)
    setDataLoading(false)
  }

  const handleOk = async () => {
    const [validateErr, values] = await to(form.validateFields())
    if (validateErr) {
      message.error('Validate Failed:', validateErr)
      return
    }

    const [err] = await to(
      axios.post(
        '/jwt/ticket-oper',
        {
          showId: currentShow.objectId,
          ...values
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      )
    )

    if (err) {
      message.error('保存失败:', err)
      return
    }

    setVisile(false)
    form.resetFields()

    getData(
      1,
      10,
      currentShow
        ? {
            showId: currentShow.objectId
          }
        : {}
    )
    setCurrentPage(1)
  }

  const remove = async id => {
    const [err] = await to(
      axios.delete(`/jwt/ticket-oper/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
    )

    if (err) {
      message.error('操作失败:', err)
    }
    getData(
      currentPage,
      10,
      currentShow
        ? {
            showId: currentShow.objectId
          }
        : {}
    )
  }

  useEffect(() => {
    getData(
      1,
      10,
      currentShow
        ? {
            showId: currentShow.objectId
          }
        : {}
    )
    setCurrentPage(1)
    if (currentShow) {
      localStorage.setItem('currentShowId', currentShow.objectId)
    }
  }, [currentShow])

  useEffect(() => {
    if (!currentShow) {
      const savedShowId = localStorage.getItem('currentShowId')
      if (!savedShowId && shows.length) {
        setCurrentShow(shows[0])
      } else {
        const show = shows.find(v => v.objectId === savedShowId)
        setCurrentShow(show)
      }
    }
  }, [shows])

  useEffect(() => {
    getShows()
  }, [])

  return (
    <div>
      <Modal
        title={currentShow && `${currentShow.name} 验票员信息`}
        open={visible}
        onOk={handleOk}
        onCancel={() => {
          form.resetFields()
          setVisile(false)
        }}
        okText="确定"
        cancelText="取消"
      >
        <Form
          form={form}
          name="userInfoForm"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          autoComplete="off"
        >
          <Form.Item
            label="姓名"
            name="agent_name"
            rules={[{ required: true, message: '请输入您的姓名!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="手机号码"
            name="agent_mobile"
            rules={[{ required: true, message: '请输入您的手机号码!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Dropdown
            overlayStyle={{ backgroundColor: '#fff', maxHeight: '10vh' }}
            trigger={['hover']}
            menu={{
              onClick: e => {
                const obj = shows.find(v => v.objectId === e.key)
                setCurrentShow(obj)
              },
              items: shows.map(v => ({
                key: v.objectId,
                label: v.name
              })),
              style: { maxHeight: '300px', overflowY: 'auto' }
            }}
          >
            <div className="cursor-pointer font-semibold text-black">
              {currentShow && currentShow.name}&nbsp;
              <DownOutlined style={{ fontSize: '10px', fontWeight: 400 }} />
            </div>
          </Dropdown>
        </Breadcrumb.Item>
        <Breadcrumb.Item>验票员查询</Breadcrumb.Item>
      </Breadcrumb>
      <Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
          background: colorBgContainer,
          borderRadius: borderRadiusLG
        }}
      >
        <div className="flex justify-end mb-5">
          <Button
            type="primary"
            icon={<UserAddOutlined />}
            onClick={() => {
              setVisile(!visible)
            }}
          >
            添加验票员
          </Button>
        </div>
        <Table
          columns={[
            {
              title: '姓名',
              key: 'agent_name',
              align: 'left',
              dataIndex: 'agent_name',
              fixed: 'left',
              width: '200px',
              render: (value, rec) => {
                return (
                  <div
                    className={` font-mono font-medium ${rec.enable ? 'text-black' : 'text-gray-600'}`}
                  >
                    {value}
                  </div>
                )
              }
            },
            {
              title: '联系号码',
              key: 'agent_mobile',
              align: 'center',
              width: '150px',
              dataIndex: 'agent_mobile'
            },
            {
              title: '展览数',
              key: 'referal_count',
              align: 'center',
              width: '70px',
              render: (_, rec) => {
                return <div>{rec.shows.length}</div>
              }
            },
            {
              title: '创建日期',
              key: 'createdAt',
              align: 'center',
              width: '160px',
              dataIndex: 'createdAt'
            },
            {
              title: '操作',
              key: 'actions',
              width: '160px',
              align: 'center',
              render: (_, rec) => {
                return (
                  <div>
                    <Popconfirm
                      title="是否删除?"
                      description={`是否把 验票员  ：${rec.agent_name}（${rec.agent_mobile}） 从${currentShow && currentShow.name} 删除？`}
                      okText="确认"
                      cancelText="取消"
                      onConfirm={() => remove(rec.objectId)}
                    >
                      <Button
                        type="text"
                        size="small"
                        icon={<UserDeleteOutlined />}
                      >
                        删除
                      </Button>
                    </Popconfirm>
                  </div>
                )
              }
            }
          ]}
          dataSource={data}
          loading={dataLoading}
          pagination={{
            position: ['bottomRight'],
            current: currentPage,
            onChange: (page, pageSize) => {
              getData(
                page,
                pageSize,
                currentShow
                  ? {
                      showId: currentShow.objectId
                    }
                  : {}
              )
              setCurrentPage(page)
            },
            total
          }}
          scroll={{
            x: 800
          }}
        />
      </Content>
    </div>
  )
}
export default Component
